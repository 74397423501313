$padding: 12px;

// ===============================================================
// Base

.desktop-window-container {
  pointer-events: none;

  position: absolute;

  top: calc(var(--desktop-navbar-height) + $padding);
  right: $padding;

  transition: opacity var(--animation-duration) ease-out;

  &.inactive {
    display: none;
  }

  &.show-anim {
    animation: slide-in-right 0.25s ease-out forwards;
    pointer-events: auto;
  }

  &.hide-anim {
    animation: slide-out-right 0.25s ease-out forwards;
    pointer-events: none;
  }
}

// ===============================================================
// Inbox

.desktop-window-container .desktop-inbox {
  width: var(--desktop-window-max-width);
  height: 480px;

  background: var(--navbar-color);

  border-radius: 16px;
  border: 1px solid var(--fill-color);
  box-shadow: var(--box-shadow-window);

  // note: inner elements have their own padding
  padding: 12px;
  padding-bottom: 16px;
  box-sizing: border-box;

  .list-layout-list {
    margin-top: 0;
  }

  .list-layout-item {
    &.unread {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

// ===============================================================
// Settings

.desktop-window-container .desktop-settings {
  width: 256px;
  // height: 280px;
  height: fit-content;

  background-color: var(--navbar-color);
  color: var(--text-color);

  border-radius: 16px;
  border: 1px solid var(--fill-color);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);

  opacity: 1;
  visibility: visible;

  padding: 24px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: 24px;

  // ----------------------
  // Items

  .desktop-settings-items {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    gap: 16px;

    .desktop-settings-item {
      cursor: pointer;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      column-gap: 12px;

      font-family: 'Pangram Medium';
      font-size: 16px; // 14px;
      text-align: left;

      margin-top: -6px;
      padding: 5px;

      .desktop-settings-item-icon {
        width: 20px;
        height: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;

        filter: var(--svg-color);
      }
      .desktop-settings-item-label {
        width: 175px;
        padding-top: 2px;
        box-sizing: border-box;
      }
    }
  }

  // -----------------------
  // Footer

  .desktop-settings-footer {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: left;
    gap: 2px;

    color: var(--text-color-75);
    font-size: 11px;

    .desktop-settings-footer-item {
      cursor: pointer;
    }
  }
}

// ===============================================================
