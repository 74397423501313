$inbox-item-height: 60px;

:root.desktop .list-layout {
  .list-layout-title {
    padding-left: 16px;
  }
}

.list-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  text-align: center;
  color: var(--text-color);

  .list-layout-title {
    font-family: 'Pangram Medium';
    font-size: var(--title-font-size);
    text-align: left;

    width: 100%;
    padding-left: 20px;
    padding-bottom: 4px;
    box-sizing: border-box;
  }

  .list-layout-tabs {
    display: flex;
    flex-direction: row;
    gap: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    font-size: 14px;

    margin-top: 20px;

    .list-layout-tab {
      font-family: 'Pangram Bold';

      color: var(--text-color-50);
      // border-bottom: unset;
      border-bottom: 1px solid var(--text-color-50);
      height: 30px;
      flex-grow: 1;

      &.active {
        color: var(--text-color);
        border-bottom: 1px solid var(--text-color);
        // color: var(--color-gold);
        // border-bottom: 1px solid var(--color-gold);
      }

      &:hover {
        color: var(--text-color);
        border-bottom: 1px solid var(--text-color);

        // color: var(--color-gold);
        // border-bottom: 1px solid var(--color-gold);
      }

      &.all {
        width: 23%;
      }
      &.owned {
        width: 38%;
      }
      &.created {
        width: 38%;
      }
    }
  }

  .list-layout-list {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    flex: 1;

    overflow-y: scroll;
    // overflow-y: auto;

    padding-bottom: 200px;
  }

  .list-layout-item {
    padding: 0 20px;
  }

  .list-layout-item-wrapper {
    display: flex;
    flex-direction: row;
    border-bottom: 0.5px solid var(--separator-color);
    padding: 10px 0;
  }

  .list-layout-item-images {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;

    width: $inbox-item-height;
    height: $inbox-item-height;

    img {
      height: 100%;
    }

    .round-picture {
      position: absolute;
      left: auto;
      right: 10px;
      width: auto;
      height: 80%;
    }

    &.cascade {
      .round-picture {
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));

        &:nth-child(1) {
          z-index: 3;
        }

        &:nth-child(2) {
          right: 15px;
          z-index: 2;
        }

        &:nth-child(3) {
          right: 20px;
          z-index: 1;
        }
      }
    }
  }

  .list-layout-item-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 14px;
    justify-content: center;

    text-align: left;

    .title {
      font-family: 'Pangram Bold';
      font-size: 22px;
    }

    .title {
      font-family: 'Pangram Bold';
      font-size: 18px;
      padding-top: 5px;
      padding-bottom: 3px;
    }

    .description {
      font-family: 'Pangram Medium';
      font-size: 13px;
      line-height: 16px;

      .white {
        color: var(--text-color);
      }
      .gray {
        color: var(--text-color-75);
      }
      .gold {
        color: var(--color-gold);
      }
    }
  }

  .list-layout-item-btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .activity-emoticon {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    overflow: hidden;
    font-size: 25px;
  }

  .round-picture {
    position: relative;
    width: 50px;
    height: 50px;
    aspect-ratio: 1;
    // border: 3px solid #c0c0c0;
    border-radius: 100%;
    left: -3px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;

    #pic-img {
      height: 100%;
      width: initial;
    }
  }

  .right-above {
    left: 50px;

    #pic-img {
      height: initial;
      width: 110%;
    }
  }
}
