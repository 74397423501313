// .desktop-navbar * {
//   pointer-events: auto;
// }

.desktop-navbar-container {
  .desktop-navbar {
    position: fixed; // because lives inside GameApp, which is pushed down
    top: 0;

    width: 100%;
    max-width: var(--app-max-width);
    min-width: 530px;

    height: calc(var(--desktop-navbar-height) + env(safe-area-inset-bottom) * 0.5);
    z-index: 5;

    border-bottom: solid 1px var(--fill-color);
    background-color: var(--navbar-color);
    color: var(--text-color);

    opacity: 1;
    visibility: visible;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    padding: 12px 24px;
    box-sizing: border-box;

    .gemz-logo {
      cursor: pointer;
      width: 92px;
      height: 24px;
      filter: var(--svg-color);
    }

    .navbar-search {
      flex-grow: 1;
      max-width: 470px;
      height: 40px;

      border-radius: 100px;
      background-color: var(--fill-color);

      padding: 8px 16px;
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 12px;

      .navbar-search-input {
        border: none;
        width: calc(100% - 28px);

        color: var(--text-color); //  #242424;
        background-color: unset;

        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: -0.26px;

        &:focus,
        input:focus {
          outline: none;
        }
      }

      .navbar-search-icon {
        cursor: pointer;
        width: 16px;
        height: 16px;
        filter: var(--svg-color);
      }
    }

    .navbar-items {
      // background-color: cyan;
      height: 40px;

      display: flex;
      flex-direction: row;
      align-items: start;
      justify-content: space-between;
      gap: 12px;

      .navbar-item {
        cursor: pointer;
        border-radius: 100px;
        border: 1px solid var(--fill-color); // #f4f5fb;

        font-size: 10px;
        font-weight: 600;
        line-height: 13px;
        text-decoration: none;
        text-align: center;
        position: relative;

        width: 40px;
        height: 40px;

        display: flex;
        flex-direction: column;

        // make create icon slightly bigger than the others
        &.item-create {
          .navbar-item-icon {
            width: 55%;
            height: 55%;
          }
        }

        .navbar-item-icon {
          width: 50%;
          height: 50%;
          margin: auto;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50%;

          filter: var(--svg-color);
        }

        .navbar-item-label {
          height: fit-content;
          user-select: none;
        }

        .navbar-item-badge {
          font-family: 'Pangram Bold', Arial, Helvetica, sans-serif;
          position: absolute;
          right: -5px;
          top: -1px;
          background-color: var(--color-gold);
          color: black;
          border-radius: 100%;
          width: 20px;
          height: 20px;
          font-size: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
